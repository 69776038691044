<template>
    <div>

        <div v-for="phone in row.item.phones">
            <a v-if="phone" :href="'tel:'+phone">{{ phone }}</a>
            <br v-else>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
    methods: {},
}
</script>